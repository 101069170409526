import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Title from '../components/page-title'
//import Overview from '../components/page-overview';
import { Flex, Box } from '@rebass/grid'
import Row from '../components/page-content-row'
import SEO from '../components/seo'
import _ from 'lodash'
import PageFacadesGrid from '../components/page-facades-grid'
import Copy from '../components/page-copy'

import Image1 from '../images/designs/happyhaus-2A-form-bold.jpg'
import Image2 from '../images/designs/happyhaus-2B-form-light.jpg'
import Image3 from '../images/designs/happyhaus-2C-form-bold.jpg'
import Image4 from '../images/designs/happyhaus-2F-form-light.jpg'
const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
  `https://happyhaus.com.au${Image3}`,
  `https://happyhaus.com.au${Image4}`,
]

export default class Forms extends Component {
  render() {
    const { data } = this.props
    const facadesData = data.facades.edges
    const imagesData = data.images.edges

    const facadesDataFiltered = _.filter(facadesData, function(o) {
      return o.node.storey === 'double'
    })

    return (
      <>
        <SEO
          title="Forms Double Storey"
          description="Forms that adapt across our double-storey design collection."
          images={ogImages}
        />
        <Row>
          <Flex>
            <Box width={1} px={2} mr={[0, '20%']}>
              <Title align="left">
                <p>
                  Forms that adapt across our double-storey design collection.
                </p>
              </Title>
              <Copy>
                <p>
                  <Link to="/forms-single">Single Storey</Link> &nbsp;{' '}
                  <Link to="/forms-double">Double Storey</Link>
                </p>
              </Copy>
            </Box>
          </Flex>
        </Row>
        <Row>
          <PageFacadesGrid facades={facadesDataFiltered} images={imagesData} />
        </Row>
      </>
    )
  }
}

export const query = graphql`
  query {
    facades: allFacadesJson {
      edges {
        node {
          id
          title
          image
          storey
        }
      }
    }
    images: allFile {
      edges {
        node {
          relativePath
          publicURL
        }
      }
    }
  }
`
