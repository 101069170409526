import React, { Component } from 'react'
import { imgUrl } from '../util/helpers'
import { Flex, Box } from '@rebass/grid'
import Row from '../components/page-content-row'
import Lightbox from 'react-images'
import styled from 'styled-components'
import Image400 from '../components/page-image-400'

export default class PageFacadesGrid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLightbox: false,
      currImage: 0,
    }
    this._onView = this._onView.bind(this)
    this._onPrev = this._onPrev.bind(this)
    this._onNext = this._onNext.bind(this)
    this._onClose = this._onClose.bind(this)
    this._gotoImage = this._gotoImage.bind(this)
  }

  _onView() {
    this.setState({
      isLightbox: true,
    })
  }

  _onNext() {
    const { currImage } = this.state
    const { facades } = this.props
    const index = currImage + 1 === facades.length ? 0 : currImage + 1
    this.setState({
      currImage: index,
    })
  }

  _onPrev() {
    const { currImage } = this.state
    const { facades } = this.props
    const index = currImage === 0 ? facades.length - 1 : currImage - 1
    this.setState({
      currImage: index,
    })
  }

  _onClose() {
    this.setState({
      isLightbox: false,
      currImage: 0,
    })
  }

  _gotoImage(index) {
    this.setState({
      isLightbox: true,
      currImage: index,
    })
  }

  _getLightbox(data) {
    const { isLightbox, currImage } = this.state
    const { facades, images } = this.props
    const facadeImages = facades.map(facade => {
      const facadeImage = facade.node.image
      const foundImages = images.filter(image => {
        return image.node.relativePath === facadeImage
      })
      if (foundImages.length > 0) {
        return { src: foundImages[0].node.publicURL }
      }
    })

    return (
      <Lightbox
        currentImage={currImage}
        images={facadeImages}
        isOpen={isLightbox}
        onClickPrev={this._onPrev}
        onClickNext={this._onNext}
        onClose={this._onClose}
        backdropClosesModal={true}
      />
    )
  }

  _getThumbs(data) {
    const self = this

    return data.map(function(facade, i) {
      const key = `facade-${i}`
      const { image, title } = facade.node
      return (
        <Box key={key} width={[1, 1 / 3]} px={2}>
          <Thumb onClick={e => self._gotoImage(i)}>
            <Image400 src={image} />
            <ThumbTitle>{title}</ThumbTitle>
          </Thumb>
        </Box>
      )
    })
  }

  render() {
    const { facades } = this.props
    const thumbs = this._getThumbs(facades)
    const lightbox = this._getLightbox(facades)

    return (
      <>
        <Row>
          <Flex flexWrap="wrap">{thumbs}</Flex>
        </Row>
        {lightbox}
      </>
    )
  }
}

const Thumb = styled.div`
  cursor: pointer;
`

// const Image = styled.div`
//   margin-bottom: 10px;
//   img {
//     display: block;
//   }
// `

const ThumbTitle = styled.div`
  margin-bottom: 10px;
  padding-bottom: 10px;
  @media (max-width: 800px) {
    padding-top: 8px;
  }
`
